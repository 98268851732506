import React from 'react'
import './styles.scss'

export const Resources = ({ data, className, id }) => {
  return (
    <div className={className} id={id}>
      {data?.length > 0 ?
        <ul>
          {
            data.map((resource, ind) => {
              return (
                <li key={ind} className="mt-2">
                  <a href={resource.url}>{resource.label}</a>
                </li>
              )
            })
          }
        </ul>
        :
        <div className="mt-2 no-resources">There are no resources to display</div>
      }
    </div>
  )
}

export default Resources