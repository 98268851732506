import React from 'react'
import OrderingInformation from '../../OrderingInformation'
import Videos from '../../Videos'
import Resources from '../../Resources'
import Specifications from '../../Specifications'
import './styles.scss'

const ProductDetailsDesktop = ({ product }) => {
  return (
    <div className="product-details desktop">
      <ul className="nav">
        {
          product.orderingInformation?.length > 0 &&
          <li className="nav-item resources-header">
            <a className="nav-link active" data-toggle="tab" href="#ordering-info">Ordering Information</a>
          </li>
        }
        {
          product.specifications?.length > 0 &&
          <li className="nav-item resources-header">
            <a className="nav-link" data-toggle="tab" href="#specs">Specifications</a>
          </li>
        }
        {
          product.videos?.length > 0 &&
          <li className="nav-item resources-header">
            <a className="nav-link" data-toggle="tab" href="#videos">Videos</a>
          </li>
        }
        {
          product.resources?.length > 0 &&
          <li className="nav-item resources-header">
            <a className="nav-link" data-toggle="tab" href="#resources">Resources</a>
          </li>
        }
      </ul>
      <div className="tab-content">
        {
          product.orderingInformation?.length > 0 &&
          <OrderingInformation data={product.orderingInformation} className="tab-pane fade show active" id="ordering-info" />
        }
        {
          product.specifications?.length > 0 &&
          <Specifications data={product.specifications} className="tab-pane fade" id="specs" />
        }
        {
          product.videos?.length > 0 &&
          <Videos className="tab-pane fade" id="videos" productName={product.hierarchyNode.name} videos={product.videos} />
        }
        {
          product.resources?.length > 0 &&
          <Resources data={product.resources} className="tab-pane fade" id="resources" />
        }
      </div>
    </div>
  )
}

export default ProductDetailsDesktop