import React from 'react'
import ProductColumnCard from '../ProductColumnCard'
import './styles.scss'

export const ProductColumnGenerator = ({ array }) => {
  return (
    <div className="row no-gutters w-100 generated-column">
      {
        array?.map((columnData, ind) => {
          if (columnData) {
            return (
              <ProductColumnCard key={ind} {...columnData} />
            )
          } else return null
        })
      }
    </div>
  )
}

export default ProductColumnGenerator