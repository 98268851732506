import React from 'react'
import './styles.scss'

const Specifications = ({ data, className, id }) => {
  return (
    <div className={className} id={id}>
    {data?.length > 0 ?
      <ul>
        {
          data.map((specification, ind) => {
            return (
              <li key={ind} className="mt-2">
                {specification.text}
              </li>
            )
          })
        }
      </ul>
      :
      <div className="mt-2 no-resources">There are no specifications to display</div>
    }
  </div>
  )
}

export default Specifications