import React, { useRef } from 'react'
import ImageGallery from 'react-image-gallery'
import { DEFAULT_IMG_URL, TABLET_MIN_WIDTH } from '../../constants'
import { useOverlay } from '../../context/overlay-context'
import { useWindowSize } from '../../hooks/useWindowResize'
import { OutsideAlerter } from '../OutsideAlerter'
import './styles.scss'

const ProductImages = ({ images }) => {
  const { setOverlay } = useOverlay()
  const { width } = useWindowSize()
  const ref = useRef()

  return (
    <OutsideAlerter onOutsideClick={() => ref?.current?.exitFullScreen()}>
      <div className="product-images">
        <ImageGallery
          ref={ref}
          showPlayButton={false}
          items={images.length > 0 ? images.map(img => {
            return {
              original: img.image,
              thumbnail: img.image,
            }
          })
            :
            [{
              original: DEFAULT_IMG_URL,
              thumbnail: DEFAULT_IMG_URL
            }]}
          thumbnailPosition="bottom"
          useBrowserFullscreen={false}
          onScreenChange={(fullscreen) => {
            window.scrollTo(0, 0)
            if (fullscreen) {
              document.body.style.overflowY = "hidden"
              setOverlay(true)
            } else {
              document.body.style.overflowY = "visible"
              setOverlay(false)
            }
          }}
          showFullscreenButton={width >= TABLET_MIN_WIDTH}
        />
      </div>
    </OutsideAlerter>
  )
}

export default ProductImages