import React from 'react'
import OrderingInformation from '../../OrderingInformation'
import Specifications from '../../Specifications'
import Videos from '../../Videos'
import Resources from '../../Resources'
import DetailsMobileCard from '../../ProductDetailsMobileCard'

const ProductDetailsMobile = ({ product }) => {
  return (
    <div className="product-details mobile accordion" id="product-details">
      {
        product.orderingInformation?.length > 0 &&
        <DetailsMobileCard
          title="Ordering Information"
          child={<OrderingInformation data={product.orderingInformation} />}
          childId="ordering-info"
          parentId="product-details"
        />
      }
      {
        product.specifications?.length > 0 &&
        <DetailsMobileCard
          title="Specifications"
          child={<Specifications data={product.specifications} />}
          childId="specs"
          parentId="product-details"
        />
      }
      {
        product.videos?.length > 0 &&
        <DetailsMobileCard
          title="Videos"
          child={<Videos videos={product.videos} />}
          childId="videos"
          parentId="product-details"
        />
      }
      {
        product.resources?.length > 0 &&
        <DetailsMobileCard
          title="Resources"
          child={<Resources data={product.resources} />}
          childId="resources"
          parentId="product-details"
        />
      }
    </div>
  )
}

export default ProductDetailsMobile