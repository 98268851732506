import React from 'react'
import './styles.scss'
import ProductColumnGenerator from '../ProductColumnGenerator'
import { DEFAULT_IMG_URL } from '../../constants'

export const RelatedProducts = ({ relatedProducts }) => {
  return (
    <>
      <h2 className="mt-6 mb-3 related-mobile">Related Products</h2>
      <div>
        <ProductColumnGenerator
          array={relatedProducts?.map(({ name, url, image }) => (
            { url, name, img: image || DEFAULT_IMG_URL, relatedProduct: true }
          ))} />
      </div>
    </>
  )
}

export default RelatedProducts