import React from 'react'
import { useWindowSize } from '../../hooks/useWindowResize'
import { TABLET_MIN_WIDTH } from '../../constants'

const Videos = ({ className, id, productName, videos }) => {
  const { width } = useWindowSize()
  return (
    <div className={className} id={id}>
      {
        videos.map((video, ind) => {
          return (
            <div key={ind} className="my-2">
              <iframe
                src={video}
                title={`${productName}-Video${ind}`}
                width={width < TABLET_MIN_WIDTH ? "100%" : "620"}
                height={width < TABLET_MIN_WIDTH ? "100%" : "340"}
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen></iframe>
            </div>
          )
        })
      }
    </div>
  )
}

export default Videos