import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import './styles.scss'

const ProductDetailsMobileCard = ({ title, childId, child, parentId }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="card mb-3">
      <div className="card-header">
        <button onClick={() => setExpanded(!expanded)} className="w-100 btn btn-link collapsed" type="button" data-toggle="collapse" data-target={`#${childId}`} aria-expanded="false" aria-controls={`#${childId}`}>
          <div className="d-flex justify-content-between">
            <div>
              {title}
            </div>
            <div>
              <FontAwesomeIcon icon={faChevronDown} className={expanded ? 'expanded' : 'collapsed'} />
            </div>
          </div>
        </button>
      </div>
      <div id={childId} className="collapse" aria-labelledby={`#${childId}`} data-parent={`#${parentId}`}>
        <div>
          {child}
        </div>
      </div>
    </div>
  )
}

export default ProductDetailsMobileCard