import React from "react"
import "./styles.scss"

export const Footnotes = ({ footnotes }) => {
  if (footnotes?.length > 0) {
    return (
      <div className="mt-2">
        {
          footnotes.map((footnote, ind) => {
            return (
              <div key={ind} className="footnote">
                {footnote.id && <sup>{footnote.id}</sup>}
                {footnote.text}
              </div>
            )
          })
        }
      </div>
    )
  } else return null
}

export default Footnotes