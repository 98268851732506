import React from 'react'
import { useWindowSize } from '../../hooks/useWindowResize'
import { TABLET_MIN_WIDTH } from '../../constants'
import Mobile from './Mobile'
import Desktop from './Desktop'

// This is an example of a component that would have two distict versions for desktop and mobile
const ProductDetails = ({ product }) => {
  const { width } = useWindowSize()

  if (width < TABLET_MIN_WIDTH) {
    return <Mobile product={product} />
  } else {
    return <Desktop product={product} />
  }
}

export default ProductDetails